import './App.css';
import DailyDoom from './components/DailyDoom';
import FAQ from './components/FAQ';
import WhyBad from './components/WhyBad';

export default function App() {
  return (
    <div className="bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 sm:py-4 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 gap-4 gap-y-12 lg:grid-cols-1 lg:gap-x-1">

        <DailyDoom />

        <WhyBad />
        <FAQ />

        </div>
      </div>
    </div>
  )
}