const posts = [
    {
      id: 1, 
      date: "health.com",
      datetime: "JULY 17, 2020",
      title: "What is Doomscrolling? Experts explain why we do it - and how to stop",
      description: "You've been seeking out a lot of bad news lately. Here's why it's time to give it a rest.",
      href: "https://www.health.com/mind-body/what-is-doomscrolling"
    },
    {
      id: 2, 
      date: "The Conversation",
      datetime: "SEP 8, 2022",
      title: "Doomscrolling is literally bad for your health.",
      description: "Doomscrolling can be a normal reaction to living through uncertain times. It’s natural to want to understand dramatic events unfolding around you and to seek out information when you’re afraid. But becoming absorbed in bad news for too long can be detrimental.",
      href: "https://theconversation.com/doomscrolling-is-literally-bad-for-your-health-here-are-4-tips-to-help-you-stop-190059"
    },
  ]
  
  export default function WhyBad() {
    return (
      <div className="relative py-8 px-8 bg-indigo-900 rounded-xl shadow-2xl">
        <div className="max-w-xl">
          <h2 id="greatesthits-heading" className="text-2xl font-extrabold tracking-tight text-white">
            Why Doom Scrolling is harmful
          </h2>
          <p className="mt-4 text-base text-gray-400">
            As much as we want to stay informed, doom scrolling can be harmful to your mental and physical health. This website can be one way to keep it at a minimum - at-a-glance - but you should seek out ways to deal with the news when you find it is becoming too much.
          </p>
        </div>
  
        <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {posts.map((post) => (
              <div key={post.title}>
                <p className="text-sm text-gray-200">
                  <time dateTime={post.datetime}>{post.date}</time>
                </p>
                <a href={post.href} className="mt-2 block">
                  <p className="text-m font-semibold text-white">{post.title}</p>
                  <p className="mt-3 text-sm text-gray-400">{post.description}</p>
                </a>
                <div className="mt-3">
                  <a href={post.href} className="text-base font-semibold text-blue-400 hover:text-indigo-500">
                    Read full story
                  </a>
                </div>
              </div>
            ))}
          </div>
      </div>
    )
  }