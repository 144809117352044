export default function DailyDoom(props) {

    return (
        <div className="grid grid-cols-1 gap-4">
            <div>
                <h2 id="details-heading" className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
                    Doom at a glance
                    
                </h2>
                <h3 id="details-subheading" className="text-lg tracking-tight text-white sm:text-base">
                Putting news into perspective one front page a time
                </h3>
            </div>
            <div>
                <div className="w-full aspect-w-3 aspect-h-2 rounded-lg overflow-hidden">
                    <a href="doom-at-a-glance.latest.png" target="_blank">
                        <img
                        src="doom-at-a-glance.latest.png"
                        alt=""
                        className="object-fill"
                        />
                        <p className="text-lg text-white">Click picture for full size</p>
                    </a>
                </div>
            </div>
        </div>
    )
  }