const faqs = [
    {
      id: 1, question: "Why does this exist?",
      answer: "Sick of looking at the news headlines, wondering what's gone wrong next? That's called doomscrolling, and it's a bad habit. Why not get a different perspective and look at ALL the doom in a single glance!  All jokes aside.. there's a perspective to be gained in seeing the headlines across different outlets that isn't easily found elsewhere.",
    },
    {
      id: 2, question: "Isn't this just making things worse?",
      answer: "Limiting exposure to just the front pages and showing 2 or more side-by-side helps one stop and take the headlines into account. There's no direct link into the articles or the pages, and this friction helps create room to reflect on what's being presented in front of you."
    },
    {
      id: 3, question: "What advice would you give to someone dealing with doom scrolling?",
      answer: "Make a set time to check the news. Avoid having news 'pushed' to you via notifications or email. Add 'friction' to break the habit. Talk with others in your household or friend network."
    }
  ]
  
  export default function FAQ() {
    return (
      <div className="relative py-8 px-8 bg-blue-900 rounded-xl shadow-2xl">
        <div className="max-w-xl">
          <h2 id="faq-heading" className="text-2xl font-extrabold tracking-tight text-white">
            Frequently asked questions
          </h2>
        </div>
  
        <dl className="mt-12 grid grid-cols-1 gap-y-10 sm:mt-16 md:grid-cols-2 md:gap-x-6 lg:grid-cols-3">
          {faqs.map((faq) => (
            <div key={faq.id}>
              <dt className="text-m font-medium text-white">{faq.question}</dt>
              <dd className="mt-3 text-sm text-gray-400">{faq.answer}</dd>
            </div>
          ))}
        </dl>
      </div>
    )
  }